import "./OurPartners.scss";
import React from "react";
import CaptioLogo from "../../assets/Captio.png";
import FintechLogo from "../../assets/Fintech.png";
import KardiologLogo from "../../assets/KardioLog_logo.png";
import TedxWarsawLogo from "../../assets/TedxWarsaw-logo.svg";
import MedreferLogo from "../../assets/Medrefer-logo.svg";

export const OurPartners = () => {
  return (
    <div className="our-partners">
      <h5>Our partners</h5>
      <div className="partners__box">
        <div className="partners__box__inner">
          <div className="icon-container">
            <img
              src={FintechLogo}
              alt="medrefer logo"
              width={"120px"}
              height={"45px"}
            />
          </div>
          <div className="icon-container">
            <img
              src={CaptioLogo}
              alt="captio logo"
              width={"120px"}
              height={"45px"}
            />
          </div>
          <div className="icon-container">
            <img
              src={KardiologLogo}
              alt="kardiolog logo"
              width={"120px"}
              height={"45px"}
            />
          </div>
          <div className="icon-container">
            <MedreferLogo alt="medrefer logo" width={"120px"} height={"45px"} />
          </div>
          <div className="icon-container">
            <TedxWarsawLogo
              alt="tedxwarsaw logo"
              width={"120px"}
              height={"45px"}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
